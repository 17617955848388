.loginButtonQ {
  width: 100%;
  border-radius: 0.1rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #E0C3FC 0%, #8EC5FC 100%);
  line-height: 0.88rem;
  border: none;
  margin-top: 0.8rem;
  margin-bottom: 1rem !important;
  font-size: 0.32rem;
  color: #FFFFFF;
}
.loginButtonQ.disabled {
  background: #e0dcdc !important;
}
.c-reg-box {
  overflow: hidden;
  background: #ffffff;
  font-size: 0.38rem;
  color: #ffffff;
}
.c-reg-box .login-logo {
  width: 100%;
  height: 3.6rem;
  margin-top: 1rem;
  position: relative;
}
.c-reg-box .login-logo .logo-shallde {
  width: 2rem;
  height: 2rem;
  box-shadow: 0rem 0.1rem 0.2rem #f3f0f0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
}
.c-reg-box .login-logo .logo-shallde img {
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.7rem;
  margin-top: -0.7rem;
}
.c-reg-box .c-reg-nav {
  width: 4.3rem;
  margin-left: 0.6rem;
  margin-top: 0.8rem;
  display: flex;
  box-sizing: border-box;
}
.c-reg-box .c-reg-nav > div {
  flex: 1;
  text-align: center;
}
.c-reg-box .c-reg-nav > div > a {
  color: #b1a9a9;
  display: block;
  cursor: pointer;
  padding: 0.1rem 0;
  position: relative;
}
.c-reg-box .c-reg-nav > div > a.active {
  color: #1e1010;
  font-weight: 600;
}
.c-reg-box .c-reg-nav > div > a.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
}
.c-reg-box .c-reg-btn {
  display: block;
  border: none;
  width: 80%;
  height: 1rem;
  color: #b1a9a9;
  border-radius: 0.6rem;
  margin: 0.4rem auto;
  font-size: 0.32rem;
  background-color: #e0dcdc;
}
.c-reg-box .c-reg-btn2 {
  display: block;
  border: none;
  width: 80%;
  height: 1rem;
  color: #ffffff;
  border-radius: 0.6rem;
  margin: 0.4rem auto;
  font-size: 0.32rem;
  background-color: #ECBB34;
}
.c-reg-panel > li:last-child {
  margin-bottom: 0;
}
.c-reg-panel > li {
  display: flex;
  border-bottom: solid 1px #EDEDED;
  margin-bottom: 0.2rem;
}
.c-reg-panel > li > .c-reg-panel-leftbox {
  flex: 1;
}
.c-reg-panel > li > .c-reg-panel-leftbox label {
  color: #cacaca;
  display: block;
  text-align: left;
  font-size: 0.28rem;
  margin-bottom: 0.1rem;
}
.c-reg-panel > li > .c-reg-panel-leftbox input {
  display: block;
  border: none;
  background: transparent;
  outline: none;
  height: 0.88rem;
  line-height: 0.88rem;
  width: 100%;
  font-size: 0.28rem;
  font-weight: 500;
  color: #02172E;
  position: relative;
  z-index: 1;
}
.c-reg-panel > li > .c-reg-panel-leftbox input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cacaca;
}
.c-reg-panel > li > .c-reg-panel-rightbox {
  line-height: 0.8rem;
  min-width: 0.4rem;
}
.c-reg-panel > li > .c-reg-panel-rightbox img {
  width: 0.4rem;
  height: 0.4rem;
}
.c-reg-panel > li > .c-reg-panel-rightbox .active {
  border: none;
  height: 0.6rem;
  padding: 0 0.26rem;
  border-radius: 0.8rem;
  color: #ffffff;
  font-size: 0.24rem;
  line-height: 0.6rem;
  background-color: #ecbb34;
}
.c-reg-panel > li > .c-reg-panel-rightbox .active span {
  color: #cacaca;
}
.c-reg-panel > li > .c-reg-panel-rightbox .c-reg-code {
  border: none;
  height: 0.6rem;
  padding: 0 0.26rem;
  border-radius: 0.8rem;
  color: #b1a9a9;
  font-size: 0.24rem;
  line-height: 0.6rem;
  background-color: #e0dcdc;
}
.c-reg-panel > li > .c-reg-panel-rightbox .c-reg-code span {
  color: #cacaca;
}
.c-reg-toreg {
  width: 100%;
  margin: 0.4rem auto 0;
}
.c-reg-toreg span {
  color: #000000;
  font-size: 16px;
}
.c-nabox {
  width: 5.6rem;
  margin: 1rem auto 0;
  text-align: right;
}
.c-nabox span {
  color: #cacaca;
  font-size: 14px;
}
.langTaggle {
  position: absolute;
  right: 0.1rem;
  color: #c3bdbd;
  width: 1.8rem;
  box-sizing: border-box;
  height: 0.46rem;
  display: flex;
  margin-top: 1.2rem;
  border: 0px;
}
.langTaggle .lang-icon {
  width: 0.58rem;
  height: 0.48rem;
  margin-right: 2px;
}
.langTaggle /deep/ .van-field {
  border: 1px rgba(0, 0, 0, 0.7) solid;
  width: 0.8rem;
  padding: 0;
  height: 0.46rem;
  font-size: 0.26rem;
}
.langTaggle /deep/ .van-field .van-field__control {
  line-height: 0.46rem;
  text-align: center;
}
.flag-list-box {
  background: #ffffff;
}
/* ----20210409--- */
.posRelat {
  position: relative;
}
.getCodeBtn {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0.15rem;
}
.getCodeBtn button {
  color: #90C4FF;
  font-size: 0.24rem;
  width: 0.96rem;
  height: 0.52rem;
  border-radius: 0.26rem;
  border: 1px solid #90C4FF;
  background: none;
}
body {
  font-family: 'PingFangSC-Regular', 'PingFang SC', "微软雅黑", "Microsoft Yahei";
}
.loginBox {
  background-color: #FFFFFF;
  position: relative;
}
.loginBox .langBtn {
  z-index: 99;
  position: absolute;
  right: 0.2rem;
  top: 0;
}
.loginBox .langBtn .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBox .langBtn .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.loginBox .loginBg {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: -0.8rem;
  height: 100vh;
  overflow: hidden;
}
.loginBox .loginBg img {
  width: 100%;
  display: block;
}
.loginBox .loginCon {
  position: relative;
  z-index: 1;
}
.loginBox .zhuceBox {
  text-align: center;
  /* position: fixed; */
  /* left: 0; */
  width: 100%;
  /* bottom: 0.5rem; */
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.loginBox .zhuceBox span {
  font-size: 0.24rem;
  color: #ff4b04;
}
.loginBox .loginCon .icon {
  padding-top: 1.2rem;
}
.loginBox .loginCon .icon img {
  width: 2.4rem;
  height: 2.4rem;
  display: block;
  margin: auto;
}
.loginBox .loginCon .icon p {
  color: white;
  font-size: 0.48rem;
  text-align: center;
}
.loginBox .login {
  padding: 0.6rem 0.3rem 0 0.3rem;
}
.loginBox .login .login_txt {
  position: relative;
}
.loginBox .login .login_txt .l_txt {
  font-size: 0.48rem;
  font-weight: 700;
}
.loginBox .login .login_txt .yy {
  position: absolute;
  left: 0;
  bottom: 0.05rem;
  z-index: -1;
  background-color: #8A8EFE;
  opacity: 0.2;
  height: 0.15rem;
  width: 1rem;
}
.loginBox .login .loginTab {
  height: 0.7rem;
  border-bottom: 1px solid #F5F5F5;
  margin: 0.8rem 0 0.7rem 0;
}
.loginBox .login .loginTab span {
  font-size: 0.32rem;
  text-align: center;
  color: #666666;
  display: inline-block;
  width: 1.7rem;
  line-height: 0.7rem;
  margin: 0 0.2rem;
}
.loginBox .login .loginTab span.on,
.loginBox .login .loginTab span.mainColor1 {
  color: #90C4FF;
  border-bottom: 1px solid #90C4FF;
}
.loginBox .login .tips {
  color: #999999;
  font-size: 0.28rem;
  text-align: center;
}
