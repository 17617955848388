


































































































































































@import url('../../assets/css/login.less');
.loginBox {
	background-color: #FFFFFF;
	position: relative;
	.langBtn {
		z-index: 99;
		position: absolute;
		right: .2rem;
		top: 0;
		.icon {
			width: .6rem;
			height: .88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: .4rem;
				height: .4rem;
			}
		}
	}
	.loginBg {
		position: absolute;
		z-index: 0;
		left: 0;
		right: 0;
		top: -.8rem;
		height: 100vh;
		overflow: hidden;
		img {
			width: 100%;
			display: block;
		}
	}
	.loginCon {
		position: relative;
		z-index: 1;
	}
	.zhuceBox {
		text-align: center;
		/* position: fixed; */
		/* left: 0; */
		width: 100%;
		/* bottom: 0.5rem; */
		margin-top: 0.5rem;
		padding-bottom: 0.5rem;
		span {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: bold;
			color: #ff4b04;
		}
	}

	.loginCon .icon {
		// padding-top: 1.42rem;
		padding-top: 1.2rem;

		img {
			// width: 3.25rem;
			width: 2.4rem;
			height: 2.4rem;
			display: block;
			margin: auto;
		}
		p {
			color: white;
			font-size: .48rem;
			text-align: center;
		}
	}

	.login {
		// padding: 1rem 1rem 0 1rem;
		padding: .6rem .3rem 0 .3rem;
		
		.login_txt {
			position: relative;
			// padding-top: 0.3rem;
			.l_txt {
				font-size: 0.48rem;
				font-weight: 700;
			}
			.yy {
				position: absolute;
				left: 0;
				bottom: 0.05rem;
				z-index: -1;
				background-color: #8A8EFE;
				opacity: 0.2;
				height: 0.15rem;
				width: 1rem;
			}
		}

		.loginTab {
			// width: 4.6rem;
			
			height: .70rem;
			border-bottom: 1px solid #F5F5F5;
			margin: .8rem 0 .7rem 0;
			span {
				font-size: 0.32rem;
				// font-family: PingFang SC;
				// font-weight: bold;
				text-align: center;
				color: #666666;
				display: inline-block;
				width: 1.7rem;
				line-height: .7rem;
				margin: 0 .2rem;
			}

			span.on,
			span.mainColor1{
				color: #90C4FF;
				border-bottom: 1px solid #90C4FF;
			}
		}
		
		.tips {
			color: #999999;
			font-size: .28rem;
			text-align: center;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;